import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./styles/app.sass";
import Page from "./components/Page";
import Home from "./screens/Home";
import Features from "./screens/Features";
import Pricing from "./screens/Pricing";
import Moderation from "./screens/Features/Moderation";
import Utilities from "./screens/Features/Utilities";
import Fun from "./screens/Features/Fun";
import Leveling from "./screens/Features/Leveling";
import Privacy from "./screens/PrivacyPolicy/PrivacyPolicy";

import Economy from "./screens/Features/Economy";
import NotFound from "./screens/404";
import Partnerships from "./screens/Features/Partnerships";
import TOS from "./screens/PrivacyPolicy/TOS";
import Cookies from "./screens/PrivacyPolicy/Cookies";
import UserPolicy from "./screens/PrivacyPolicy/UserPolicy";

function App() {
  return (
    <Router>
      <Switch>
        <Route
          exact
          path="/"
          render={() => (
            <Page>
              <Home />
            </Page>
          )}
        />
        <Route exact path={'/privacy'} render={() => (
          <Page>
            <Privacy />
          </Page>
        )} />
        <Route exact path={'/tos'} render={() => (
          <Page>
            <TOS />
          </Page>
        )} />

        <Route exact path={'/cookies'} render={() => (
          <Page>
            <Cookies />
          </Page>
        )} />

        <Route exact path={'/usepolicy'} render={() => (
          <Page>
            <UserPolicy />
          </Page>
        )} />
        <Route
          exact
          path="/features"
          render={() => (
            <Page>
              <Features />
            </Page>
          )}
        />
        <Route
          exact
          path="/prime"
          render={() => (
            <Page>
              <Pricing />
            </Page>
          )}
        />

        <Route
          exact
          path="/moderation"
          render={() => (
            <Page>
              <Moderation />
            </Page>
          )}
        />

        <Route
          exact
          path="/utilities"
          render={() => (
            <Page>
              <Utilities />
            </Page>
          )}
        />



        <Route
          exact
          path="/partnerships"
          render={() => (
            <Page>
              <Partnerships />
            </Page>
          )}
        />


        <Route
          exact
          path="/fun"
          render={() => (
            <Page>
              <Fun />
            </Page>
          )}
        />

        <Route
          exact
          path="/leveling"
          render={() => (
            <Page>
              <Leveling />
            </Page>
          )}
        />


        <Route
          exact
          path="/economy"
          render={() => (
            <Page>
              <Economy />
            </Page>
          )}
        />

        <Route path="*">
          <Page>
            <NotFound />

          </Page>
        </Route>

      </Switch>
    </Router>
  );
}

export default App;
