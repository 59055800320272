import React from "react";
import cn from "classnames";
import Slider from "react-slick";
import styles from "./Lifestyle.module.sass";
import Icon from "../../../components/Icon";
import ScrollParallax from "../../../components/ScrollParallax";
import { Link } from "react-router-dom";

const items = [
  {
    title: "Lights, Camera Action!",
    image: "/landing/images/content/Film_Countdown.png",
    image2x: "/landing/images/content/Film_Countdown.png",
    content:
      "Host virtual movie nights right on your server! You can create it as an event, search for the movie you want to watch, and Mochi will remind you and your members when it's movie time!",
  },
  // {
  //   title: "Share your favorite moments with Starboards!",
  //   image: "/landing/images/content/starboard.png",
  //   image2x: "/landing/images/content/starboard.png",
  //   content:
  //     "Highlight your favorite community quotes and moments with starboards! You and your community can vote to share your favorites server moments by reacting to your messages and sending them to a starboard channel!",
  // },
  {
    title: "Organize better giveaways!",
    image: "/landing/images/content/giveaways.png",
    image2x: "/landing/images/content/giveaways.png",
    content:
      "Create and organize highly customized giveaways with custom banners, embeds, and icons to suit your server's theme! You can allow members multiple entries and create level and role requirements to qualify for entry!",
  },

  {
    title: "Birthdays",
    image: "/landing/images/content/Calendar.png",
    image2x: "/landing/images/content/Calendar.png",
    content:
      "Never miss another birthday! Send out customized birthday wishes in the birthday channel and celebrate birthdays in your community!",
  },

  {
    title: "Question of the Day",
    image: "/landing/images/content/School.png",
    image2x: "/landing/images/content/School.png",
    content:
      "Use Mochi's questions of the day or create your own based on your community and interests! You can even accept suggestions from your community and approve the best ones!",
  },


  {
    title: "Trivia",
    image: "/landing/images/content/Books.png",
    image2x: "/landing/images/content/Books.png",
    content:
      "Mochi has a large database of fun trivia questions from several topics ranging from anime to history! You can host trivia and challenge your community to test their knowledge from over 7 topics!",
  },

  {
    title: "Express yourself with Reactions!",
    image: "/landing/images/content/Selfie.png",
    image2x: "/landing/images/content/Selfie.png",
    content:
      "Express yourself with over 25 fun anime reactions that can help you express yourself in fun ways!",
  },








];

const SlickArrow = ({ currentSlide, slideCount, children, ...props }) => (
  <button {...props}>{children}</button>
);

const Fun = () => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    nextArrow: (
      <SlickArrow>
        <Icon name="arrow-next" size="14" />
      </SlickArrow>
    ),
    prevArrow: (
      <SlickArrow>
        <Icon name="arrow-prev" size="14" />
      </SlickArrow>
    ),
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 100000,
        settings: "unslick",
      },
    ],
  };

  return (
    <div className={cn("section-pb", styles.section)}>
      <div className={cn("container", styles.container)}>
        <Link to='/features'><button className={cn("button")}><Icon name="arrow-prev" size="14" style={{ marginLeft: 0, marginRight: '5px' }} /> Back</button></Link>

        <div className={styles.head}>
          {/* <div className={cn("stage-small", styles.stage)}>explore the app</div> */}
          <h2 className={cn("h2", styles.title)}>Fun</h2>
          <div className={styles.info}>
            Fun activities to keep your community engaged!{" "}
          </div>
          {/* <Link
            className={cn("button-stroke", styles.button)}
            to="/class02-details"
          >
            <span>Join a class today</span>
            <Icon name="arrow-right" size="10" />
          </Link> */}
        </div>
        <div className={styles.wrap}>
          <Slider
            className={cn("lifestyle-slider", styles.slider)}
            {...settings}
          >
            {items.map((x, index) => (
              <ScrollParallax className={styles.item} key={index}>
                <div className={styles.row}>
                  <div className={styles.col}>
                    <div className={styles.details}>
                      <div className={styles.number}>0{index + 1}.</div>
                      <div className={styles.category}>{x.title}</div>
                      <div className={styles.content}>{x.content}</div>
                    </div>
                  </div>
                  <div className={styles.col}>
                    <img
                      srcSet={`${x.image2x} 2x`}
                      src={x.image}
                      alt="Lifestyle"
                    />
                  </div>
                </div>
              </ScrollParallax>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Fun;
