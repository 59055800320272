const TOS = () => {
    return (<div className="container">

        <h2>Terms of Service for Mochi! Discord Bot</h2>
        <br />
        <p><strong>Usage Agreement</strong></p>
        <p>By inviting Mochi! Discord Bot and using its features (commands, welcome system), you agree to the Terms and Privacy Policy of the bot as stated below. You acknowledge that you have the privilege to use the bot freely on any Discord Server you share with it, and that you can invite it to any Server that you have &quot;Manage Server&quot; rights for. Please note that this privilege may be revoked if you violate the terms and/or policy of this bot, or the Terms of Service, Privacy Policy, and/or Community Guidelines of Discord Inc.</p>
        <br />
        <p><strong>Data Collection</strong></p>
        <p>By inviting Mochi! Discord Bot, it may collect specific data as described in its Privacy Policy. This data is intended to be used for core functionalities of the bot, such as command handling, guild-specific settings, and the Welcoming System.</p>
        <br />
        <p><strong>Intended Age</strong></p>
        <p>Individuals under the minimum age described in Discord&#39;s Terms of Service may not use the bot. Features, such as commands which are marked as &quot;NSFW&quot; (Not Safe For Work), may not be used by individuals under the age of maturity in their country. Violating these terms will result in the removal of the bot from any servers you own.</p>
        <br />
        <p><strong>Affiliation</strong></p>
        <p>Mochi! Discord Bot is not affiliated with, supported or made by Discord Inc. Any direct connection to Discord or any of its trademark objects is purely coincidental. We do not claim to have the copyright ownership of any of Discord&#39;s assets, trademarks, or other intellectual property.</p>
        <br />
        <p><strong>Liability</strong></p>
        <p>The owner of Mochi! Discord Bot may not be held liable for individuals breaking these Terms at any given time. The owner trusts that the end users will be truthful about their information and will not misuse this bot or the services of Discord Inc in a malicious way.</p>
        <br />
        <p><strong>Updates to the Terms of Service</strong></p>
        <p>We reserve the right to update these terms at our own discretion. If there are any updates, you will be given a 1-week (7 days) period to opt-out of these terms if you do not agree with the new changes. You may opt-out by removing Mochi! Discord Bot from any server you have rights to.</p>
        <br />
        <p><strong>Contact</strong></p>
        <p>If you need any support, you may contact us through email at <a href="mailto:info@mochi.bot">info@mochi.bot</a> or through the official support Discord of the bot. We may provide other ways of support, but they are not guaranteed.</p>
        <br />
        {/* <h2>Wordventures LLC Terms of Service</h2>
        <p>These Terms of Service govern your use of the website located at <a href="https://mochi.bot/">https://mochi.bot/</a> and any related services provided by Wordventures LLC. </p>
        <p>By accessing <a href="https://mochi.bot/">https://mochi.bot/</a>, you agree to abide by these Terms of Service and to comply with all applicable laws and regulations. If you do not agree with these Terms of Service, you are prohibited from using or accessing this website or using any other services provided by Wordventures LLC. </p>
        <p>We, Wordventures LLC, reserve the right to review and amend any of these Terms of Service at our sole discretion. Upon doing so, we will update this page. Any changes to these Terms of Service will take effect immediately from the date of publication. </p>
        <p>These Terms of Service were last updated on 6 February 2023. </p>
        <h3>Limitations of Use</h3>
        <p>By using this website, you warrant on behalf of yourself, your users, and other parties you represent that you will not: </p>
        <ol>
            <li>modify, copy, prepare derivative works of, decompile, or reverse engineer any materials and software contained on this website;</li>
            <li>remove any copyright or other proprietary notations from any materials and software on this website;</li>
            <li>transfer the materials to another person or “mirror” the materials on any other server;</li>
            <li>knowingly or negligently use this website or any of its associated services in a way that abuses or disrupts our networks or any other service Wordventures LLC provides;</li>
            <li>use this website or its associated services to transmit or publish any harassing, indecent, obscene, fraudulent, or unlawful material;</li>
            <li>use this website or its associated services in violation of any applicable laws or regulations;</li>
            <li>use this website in conjunction with sending unauthorized advertising or spam;</li>
            <li>harvest, collect, or gather user data without the user’s consent; or</li>
            <li>use this website or its associated services in such a way that may infringe the privacy, intellectual property rights, or other rights of third parties.</li>
        </ol>
        <h3>Intellectual Property</h3>
        <p>The intellectual property in the materials contained in this website are owned by or licensed to Wordventures LLC and are protected by applicable copyright and trademark law. We grant our users permission to download one copy of the materials for personal, non-commercial transitory use. </p>
        <p>This constitutes the grant of a license, not a transfer of title. This license shall automatically terminate if you violate any of these restrictions or the Terms of Service, and may be terminated by Wordventures LLC at any time. </p>
        <h3>Liability</h3>
        <p>Our website and the materials on our website are provided on an 'as is' basis. To the extent permitted by law, Wordventures LLC makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties including, without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property, or other violation of rights. </p>
        <p>In no event shall Wordventures LLC or its suppliers be liable for any consequential loss suffered or incurred by you or any third party arising from the use or inability to use this website or the materials on this website, even if Wordventures LLC or an authorized representative has been notified, orally or in writing, of the possibility of such damage. </p>
        <p>In the context of this agreement, &ldquo;consequential loss&rdquo; includes any consequential loss, indirect loss, real or anticipated loss of profit, loss of benefit, loss of revenue, loss of business, loss of goodwill, loss of opportunity, loss of savings, loss of reputation, loss of use and/or loss or corruption of data, whether under statute, contract, equity, tort (including negligence), indemnity, or otherwise. </p>
        <p>Because some jurisdictions do not allow limitations on implied warranties, or limitations of liability for consequential or incidental damages, these limitations may not apply to you. </p>
        <h3>Accuracy of Materials</h3>
        <p>The materials appearing on our website are not comprehensive and are for general information purposes only. Wordventures LLC does not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the materials on this website, or otherwise relating to such materials or on any resources linked to this website. </p>
        <h3>Links</h3>
        <p>Wordventures LLC has not reviewed all of the sites linked to its website and is not responsible for the contents of any such linked site. The inclusion of any link does not imply endorsement, approval, or control by Wordventures LLC of the site. Use of any such linked site is at your own risk and we strongly advise you make your own investigations with respect to the suitability of those sites. </p>
        <h3>Right to Terminate</h3>
        <p>We may suspend or terminate your right to use our website and terminate these Terms of Service immediately upon written notice to you for any breach of these Terms of Service. </p>
        <h3>Severance</h3>
        <p>Any term of these Terms of Service which is wholly or partially void or unenforceable is severed to the extent that it is void or unenforceable. The validity of the remainder of these Terms of Service is not affected. </p>
        <h3>Governing Law</h3>
        <p>These Terms of Service are governed by and construed in accordance with the laws of Delaware. You irrevocably submit to the exclusive jurisdiction of the courts in that State or location. </p> */}

    </div>)
}

export default TOS;