import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./Workouts.module.sass";
import Image from "../Image";

const Workouts = () => {
  return (
    <div className={styles.section}>
      <div className={cn("container", styles.container)}>
        <div className={styles.gallery}>
          <div className={styles.preview}>
            <Image
              srcSet="/landing/images/content/mochi-preview.png"
              srcSetDark="/landing/images/content/mochi-preview.png"
              src="/landing/images/content/mochi-preview.png"
              srcDark="/landing/images/content/mochi-preview.png"
              alt="Phones"
            />
          </div>
        </div>
        <div className={styles.wrap}>
          <h2 className={cn("h2", styles.title)}>
            Manage your server from anywhere!
          </h2>
          <div className={styles.info}>
            We made Mochi mobile-friendly and responsive so that you can access
            the Mochi dashboard from any device.
          </div>
          {/* <ul className={styles.list}>
            {items.map((x, index) => (
              <li className={styles.item} key={index}>
                {x}
              </li>
            ))}
          </ul> */}
          <div className={styles.btns}>
            <a className={cn("button", styles.button)} href="https://api.mochi.bot/auth/discord">
              Login with Discord
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Workouts;
