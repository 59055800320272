import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import cn from "classnames";
import styles from "./Footer.module.sass";
import Icon from "../Icon";
import Image from "../Image";

const menu = [
  // {
  //   title: "Pricing",
  //   url: "/pricing",
  // },
  {
    title: "Features",
    url: "/features",
  },

  {
    title: "Terms and Conditions",
    url: "/tos",
  },

  {
    title: "Privacy",
    url: "/privacy",
  },
  {
    title: "Cookies",
    url: "/cookies"
  }, {
    title: "Acceptable Use",
    url: "/usepolicy"
  }
];

const socials = [
  {
    title: "discord",
    size: "16",
    url: "https://discord.gg/krsFJDS2hA",
  },

  {
    title: "twitch",
    size: "16",
    url: "https://www.twitch.tv/mochithebot",
  },

  {
    title: "youtube",
    size: "16",
    url: "https://youtube.com/@mochithebot",
  },

  {
    title: "twitter",
    size: "18",
    url: "https://twitter.com/mochithebot",
  },
  {
    title: "instagram",
    size: "16",
    url: "https://www.instagram.com/mochithebot/",
  },
];

const Footer = () => {
  const [visible, setVisible] = useState(false);

  return (
    <footer className={styles.footer}>
      <div className={styles.body}>
        <div className={cn("container", styles.container)}>
          <div className={styles.col}>
            <Link className={styles.logo} to="/">
              <Image
                className={styles.pic}
                src="/landing/images/mochi-2022.png"
                srcDark="/landing/images/mochi-2022.png"
                alt="Mochi"
              />
            </Link>
          </div>
          <div className={styles.col}>
            <div className={cn(styles.item, { [styles.active]: visible })}>
              <div
                className={styles.category}
                onClick={() => setVisible(!visible)}
              >
                Info
                <Icon name="arrow-bottom" size="9" />
              </div>
              <div className={styles.menu}>
                {menu.map((x, index) => (
                  <NavLink
                    className={styles.link}
                    activeClassName={styles.active}
                    to={x.url}
                    key={index}
                  >
                    {x.title}
                  </NavLink>
                ))}
              </div>
            </div>
          </div>
          <div className={styles.col}>
            <div className={styles.category}>Socials</div>
            <div className={styles.socials}>
              {socials.map((x, index) => (
                <a
                  className={styles.social}
                  href={x.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  key={index}
                >
                  <Icon name={x.title} size={x.size} />
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.foot}>
        <div className={cn("container", styles.container)}>
          <div className={styles.copyright}>
            Copyright © 2023 Team Mochi ♥️ All rights reserved
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
