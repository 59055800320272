import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import cn from "classnames";
import styles from "./Header.module.sass";
import DropdownMenu from "./DropdownMenu";
import Icon from "../Icon";
import Image from "../Image";

const navLinks = [
  {
    title: "Features",
    url: "/features",
  },
  {
    title: "Prime",
    url: "/prime",
  },

  // {
  //   title: "Tutorials",
  //   url: "/tutorials",
  // },

  // {
  //   title: "Download",
  //   url: "/download",
  // },
  // {
  //   title: "Class",
  //   content: {
  //     menu: [
  //       {
  //         title: "Program Videos",
  //         url: "/class01",
  //         image: "/landing/images/menu-video.svg",
  //       },
  //       {
  //         title: "Premium Class",
  //         url: "/class02",
  //         image: "/landing/images/menu-class.svg",
  //       },
  //     ],
  //     links: [
  //       {
  //         title: "Sweet and Tone",
  //         url: "/class01-details",
  //         image: "/landing/images/content/header-pic-1.png",
  //         image2x: "/landing/images/content/header-pic-1@2x.png",
  //         category: "black",
  //         categoryText: "featured class",
  //         avatar: "/landing/images/content/avatar-1.png",
  //         trainer: "Zack Beier",
  //         content:
  //           "Sweet and Tone is a seven-day bodyweight training program designed to boost your strength and endurance over the course of a week.",
  //         level: "green",
  //         levelText: "beginner",
  //       },
  //       {
  //         title: "Sweet and Tone",
  //         url: "/class01-details",
  //         image: "/landing/images/content/header-pic-2.png",
  //         image2x: "/landing/images/content/header-pic-2@2x.png",
  //         category: "green",
  //         categoryText: "yoga",
  //         avatar: "/landing/images/content/avatar-2.png",
  //         trainer: "Zack Beier",
  //       },
  //       {
  //         title: "Sweet and Tone",
  //         url: "/class01-details",
  //         image: "/landing/images/content/header-pic-3.png",
  //         image2x: "/landing/images/content/header-pic-3@2x.png",
  //         category: "purple",
  //         categoryText: "mindfulness",
  //         avatar: "/landing/images/content/avatar-3.png",
  //         trainer: "Zack Beier",
  //       },
  //       {
  //         title: "Sweet and Tone",
  //         url: "/class01-details",
  //         image: "/landing/images/content/header-pic-4.png",
  //         image2x: "/landing/images/content/header-pic-4@2x.png",
  //         category: "red",
  //         categoryText: "fitness",
  //         avatar: "/landing/images/content/avatar-4.png",
  //         trainer: "Zack Beier",
  //       },
  //     ],
  //     trainer: [
  //       {
  //         title: "Boyd Reinger",
  //         avatar: "/landing/images/content/avatar-1.png",
  //         type: "Personal trainer",
  //       },
  //       {
  //         title: "Randal Jacobson",
  //         avatar: "/landing/images/content/avatar-2.png",
  //         type: "Personal trainer",
  //       },
  //       {
  //         title: "Dwight Schamberger",
  //         avatar: "/landing/images/content/avatar-3.png",
  //         type: "Personal trainer",
  //       },
  //       {
  //         title: "Omari Gulgowski",
  //         avatar: "/landing/images/content/avatar-4.png",
  //         type: "Personal trainer",
  //       },
  //     ],
  //   },
  // },
  // {
  //   title: "Lifestyle",
  //   url: "/lifestyle",
  // },
];

const socials = [
  {
    title: "discord",
    size: "16",
    url: "https://discord.gg/krsFJDS2hA",
  },

  {
    title: "twitch",
    size: "16",
    url: "https://www.twitch.tv/mochithebot",
  },

  {
    title: "youtube",
    size: "16",
    url: "https://youtube.com/@mochithebot",
  },

  {
    title: "twitter",
    size: "18",
    url: "https://twitter.com/mochithebot",
  },
  {
    title: "instagram",
    size: "16",
    url: "https://www.instagram.com/mochithebot/",
  },
];

const Headers = () => {
  const [visibleNav, setVisibleNav] = useState(false);

  return (
    <header className={styles.header}>
      <div className={cn("container", styles.container)}>
        <Link
          className={styles.logo}
          to="/"
          onClick={() => setVisibleNav(false)}
        >
          <Image
            className="navlogo"
            src="/landing/images/logo-dark.svg"
            srcDark="/landing/images/mochi-2022.png"
          />
        </Link>
        <div className={cn(styles.wrap, { [styles.active]: visibleNav })}>
          <nav className={styles.nav}>
            {navLinks.map((x, index) =>
              x.content ? (
                <DropdownMenu
                  className={styles.group}
                  item={x}
                  key={index}
                  setValue={setVisibleNav}
                />
              ) : (
                <NavLink
                  className={styles.link}
                  activeClassName={styles.active}
                  to={x.url}
                  key={index}
                  onClick={() => setVisibleNav(false)}
                >
                  {x.title}
                </NavLink>
              )
            )}
          </nav>
          <div className={styles.details}>
            <div className={styles.socials}>
              {socials.map((x, index) => (
                <a
                  className={styles.social}
                  href={x.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  key={index}
                >
                  <Icon name={x.title} size={x.size} />
                </a>
              ))}
            </div>
          </div>
        </div>

        <a className={cn("button-stroke button-small", styles.button)} target="_blank" href="https://patreon.com/mochiprime">
          <img style={{ width: '45px', padding: '10px' }} srcDark='landing/images/prime.svg' src='landing/images/prime.svg' />
          Mochi Prime
        </a>

        <button
          className={cn(styles.burger, { [styles.active]: visibleNav })}
          onClick={() => setVisibleNav(!visibleNav)}
        ></button>
      </div>
    </header>
  );
};

export default Headers;
