import React, { useRef } from "react";
import Program from "./Program";


const Features = () => {
  const scrollToRef = useRef(null);

  return (
    <>

      <Program />

    </>
  );
};

export default Features;
