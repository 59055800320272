import React, { useState } from "react";
import cn from "classnames";
import styles from "./Plan.module.sass";
import Icon from "../../../components/Icon";

const options = [
  {
    title: "Features",
    items: [
      {
        title: "Embeds",
        description: "Create and customize embeds.",
      },
      {
        title: "Autoresponders",
        description: "Sends an automated message, embed, adds or removes a role, gives or takes currency or provides xp.",
      },


      {
        title: "Emoteresponders",
        description: "Send an emote when a member sends a message with a trigger word.",
      },

      {
        title: "Reaction Roles",
        description: "Members can react to receive or remove a role.",
      },



      {
        title: "Customizable XP and cooldowns",
        description: "Customize how much XP members can earn and decide the cooldown for XP.",
      },



      // {
      //   title: "Network Linking",
      //   description: "Link upto 3 other servers and compete in message, currency and level leaderboards!",
      // },


      // {
      //   title: "Message Leaderboards",
      //   description: "Leaderboard for the most messages sent in your server weekly or monthly!",
      // },


      // {
      //   title: "Currency Leaderboards",
      //   description: "Leaderboard for the most currency earned in your server weekly or monthly!",
      // },


      // {
      //   title: "Level Leaderboards",
      //   description: "Leaderboard for the most xp earned in your server weekly or monthly!",
      // },


      {
        title: "Voice Leveling",
        description: "Members can earn XP by joining and speaking in voice channels.",
      },


      {
        title: "Level Rewards",
        description: "Set level rewards when members reach certain levels and complete XP requirements.",
      },

      // {
      //   title: "Boosted XP Roles",
      //   description: "Set boosted XP roles that give members extra XP, currency as extra rewards.",
      // },


      {
        title: "Work Commands",
        description: "Create customized work commands that members can use to earn XP and/or currency!",
      },



      {
        title: "Custom Stores",
        description: "Create custom stores for members to purchase with in-server currency! Custom stores can have custom banners for storefronts and embed colors!",
      },

      {
        title: "Store Items",
        description: "Create store items to be sold in your custom stores!",
      },

      // {
      //   title: "Consumable Items",
      //   description: "Create consumable, one-time rewards, xp boosts or other rewards!",
      // },


      // {
      //   title: "Activity Income",
      //   description: "Reward members for being active in text channels with random currency drops!",
      // },




      {
        title: "Twitch Alerts",
        description: "Get notifications when you and your favorite streamers are live on Twitch!",
      },

      {
        title: "Twitch Live Roles",
        description: "Give your members a live Twitch role when they go live on Twitch, removes the role when they go offline!",
      },


      {
        title: "Repeat Messages",
        description: "Send repeated reminders and notifications to your members!",
      },

      {
        title: "Giveaways",
        description: "Host custom giveaways by adding your own banner images, icons and brand colors in your giveaways.",
      },


      {
        title: "Movie Night",
        description: "Create an event in your server to watch a movie. Choose your Movie or TV show and set an alert for your members!",
      },



      {
        title: "Twitter Alerts",
        description: "Send notifications when you or your favorites post on Twitter!",
      },

      {
        title: "YouTube Alerts",
        description: "Send notifications when you or your favorites post videos on YouTube!",
      },




      // {
      //   title: "Instagram Alerts",
      //   description: "Send notifications when you or your favorites post on Instagram!",
      // },







      {
        title: "Auto Purge",
        description: "Schedule automatic deletion of messages in text channels!",
      },

      // {
      //   title: "Partnerships",
      //   description: "Create partnerships and affiliate channels and track your progress with leaderboards!",
      // },



      {
        title: "Question of the Day",
        description: "Use our default questions of the day, that ask your members a question a day, or use your own questions, and allow members to submit their own!",
      },


      {
        title: "Trivia",
        description: "Members can request a random trivia question!",
      },


      {
        title: "Polls",
        description: "Create fun and engaging polls!",
      },

      // {
      //   title: "Custom Starboard",
      //   description: "Customize the starboard icon for your starboards!",
      // },



    ],
  },
];

const data = [
  {
    title: "Free",
    color: "#fffff",
    description: "Basic Plan",
    price: "0",
    note: "/month",
    options: [
      "5",
      "Unlimited",
      "Unlimited",
      "5",
      "false",
      "false",
      "5",
      "3",
      "2",
      "4",
      "1",
      "1",
      "3",
      "3",
      "1",
      "false",
      "true",
      "false",
      "true",
      "true",
      "true"


    ],
  },
  {
    title: "Prime",
    color: "#9757D7",
    description: "Unlock all features for 1 server",
    price: "5",
    note: "/month",
    button: "Upgrade Now",
    options: [
      "Unlimited",
      "Unlimited",
      "Unlimited",
      "50",
      "true",
      "true",
      "50",
      "25",
      "5",
      "50",
      "5",
      "5",
      "20",
      "10",
      "20",

      "true",
      "true",
      "true",
      "true",
      "true",
      "true"



    ],
  },

];

const Plan = () => {
  const [more, setMore] = useState([false, false, false]);

  const handleClick = (index) => {
    let newMore = [...more];
    newMore[index] = !more[index];

    setMore(newMore);
  };

  const renderContent = (content) => {
    if (content === "true") {
      return <Icon name="check" size="14" />;
    }
    if (content === "false") {
      return <div className={styles.minus}>-</div>;
    }
    return <div className={styles.minus}>{content}</div>;
  };

  return (
    <div className={cn("section-bg", styles.section)}>
      <div className={cn("container", styles.container)}>

        <h1 className={cn("h1", styles.title)}>
          Compare Plans
        </h1>
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.col}>
              <div className={styles.head}></div>
              <div className={styles.body}>
                {options.map((option, index) => (
                  <div className={styles.item} key={index}>
                    <div className={styles.category}>{option.title}</div>
                    {option.items.map((item, index) => (
                      <div className={styles.parameter} key={index}>
                        <div className={styles.label}>{item.title}</div>
                        <div className={styles.hint}>
                          <Icon name="info" size="10" />
                          <div className={styles.tooltip}>
                            {item.description}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
            {data.map((type, index) => (
              <div className={styles.col} key={index}>
                <div className={styles.head}>
                  <div className={styles.package} style={{ color: type.color }}>
                    {type.title}
                  </div>
                  <div className={styles.description}>{type.description}</div>
                  {type.price && (
                    <>
                      <div className={styles.cost}>
                        <span className={styles.sign}>$</span>{" "}
                        <span className={styles.price}>{type.price}</span>
                      </div>
                      <div className={styles.note}>{type.note}</div>
                    </>
                  )}
                </div>
                <div className={styles.body}>
                  <div
                    className={cn(styles.more, {
                      [styles.active]: more[index],
                    })}
                    onClick={() => handleClick(index)}
                  >
                    See all features
                    <Icon name="arrow-bottom" size="9" />
                  </div>
                  <div
                    className={cn(styles.list, {
                      [styles.visible]: more[index],
                    })}
                  >
                    {options.map((option, optionIndex) => (
                      <div className={styles.item} key={optionIndex}>
                        {option.items.map((item, itemIndex) => (
                          <div className={styles.parameter} key={itemIndex}>
                            <div className={styles.label}>{item.title}</div>
                            {renderContent(type.options[itemIndex])}
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>

                  {type.button ? <a target={'_blank'} href='https://www.patreon.com/mochiprime'> <button
                    className={cn(
                      { button: index === 0 },
                      { button: index === 1 },
                      { "button-stroke": index === 2 },
                      styles.button
                    )}
                  >
                    {type.button}
                  </button></a> : null}

                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Plan;
