import React, { useRef } from "react";
// import styles from "./Home.module.sass";
import Hero from "./Hero";
import Intro from "../../components/Intro";
import ValueProps from "../../components/ValueProps";

import Workouts from "../../components/Workouts";
import Offer from "../../components/Offer";

const Home = () => {
  const scrollToRef = useRef(null);

  return (
    <>
      <Hero scrollToRef={scrollToRef} />
      {/* <Clients /> */}
      {/* <Plan /> */}
      {/* <Steps scrollToRef={scrollToRef} /> */}


      <Intro />
      {/* <Intro /> */}


      {/* <Book /> */}

      <ValueProps className="section" />
      {/* <About /> */}
      {/* <Team /> */}
      {/* <Review className="section" /> */}
      {/* <Quality /> */}
      {/* <Advantages /> */}
      <Workouts />
      <Offer className="section-border-top" />
    </>
  );
};

export default Home;
