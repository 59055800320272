import cn from "classnames";
import { Link } from "react-router-dom";
import styles from './404.module.sass'

const NotFound = () => {

    return (
        <>

            <div style={{ paddingTop: '5%' }}>


                <div style={{ textAlign: 'center' }} >
                    <img src='/landing/images/mod-mochi.svg' width={105} height={105} />

                </div>

                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    paddingBottom: '10%'
                }}>




                    <div className={cn("notFound")} style={{ textAlign: 'center' }}>
                        <h2 style={{ padding: '30px' }}>Oops, the page you are looking for doesn't exist!</h2>
                        <Link to='/'>
                            <button className={cn("button")}>Return to Mochi</button>

                        </Link>
                    </div>


                </div >

            </div >


        </>
    )

}

export default NotFound;