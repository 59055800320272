import React from "react";
import cn from "classnames";
import styles from "./Hero.module.sass";
import Image from "../../../components/Image";
import ScrollButton from "../../../components/ScrollButton";
import ScrollParallax from "../../../components/ScrollParallax";

const Hero = ({ scrollToRef }) => {
  return (
    <div className={styles.hero}>
      <div className={cn("container", styles.container)}>
        <ScrollParallax className={styles.wrap}>
          <div className={cn("stage", styles.stage)}>
            Manage your server stress-free
          </div>
          <h1 className={cn("h1", styles.title)}>
            Mochi, Your Magical Discord Assistant!
          </h1>
          <div className={styles.text}>
            With Mochi by your side, managing your Discord server is a breeze! Spend more time creating content and keeping your community engaged!
          </div>
          <div className={styles.btns}>
            <a target="_blank" className={cn("button", styles.button)} href="https://discord.com/oauth2/authorize?client_id=878279819928944701&permissions=1383919447126&scope=bot%20applications.commands">
              <img style={{ width: '45px', padding: '10px' }} srcDark='landing/images/login.svg' src='landing/images/discord.svg' />
              Add to Discord
            </a>

            <a
              className={cn("button-stroke", styles.button)}
              href="https://api.mochi.bot/auth/discord"
            >
              <img style={{ width: '45px', padding: '10px' }} srcDark='landing/images/login.svg' src='landing/images/login.svg' />
              Login with Discord
            </a>
          </div>
        </ScrollParallax>
        <ScrollButton
          onScroll={() =>
            scrollToRef.current.scrollIntoView({ behavior: "smooth" })
          }
          className={styles.scroll}
        />
        <div className={styles.gallery}>
          <ScrollParallax className={styles.preview} animateIn="fadeInUp">
            <Image
              className={'heroImage'}
              srcSet="/landing/images/content/main-mochi.svg"
              srcSetDark="/landing/images/content/main-mochi.svg"
              src="/landing/images/content/main-mochi.svg"
              srcDark="/landing/images/content/main-mochi.svg"
              alt="Watch"
            />
          </ScrollParallax>


        </div>
      </div>
    </div>
  );
};

export default Hero;
