import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./Offer.module.sass";

const Offer = ({ className }) => {
  return (
    <div className={cn(className, styles.section)}>
      <div className={cn("container", styles.container)}>
        {/* <div className={cn("stage", styles.stage)}>
          try it free for 14 days 🎾
        </div> */}
        <h2 className={cn("h1", styles.title)}>
          Start building your perfect server, today!
        </h2>
        {/* <div className={styles.text}>
          We made Mochi mobile-friendly and responsive so that you can access the Mochi dashboard from any device.
        </div> */}
        <a className={cn("button", styles.button)} target="_blank" href="https://discord.com/oauth2/authorize?client_id=878279819928944701&permissions=1383919447126&scope=bot%20applications.commands">
          Add to Discord
        </a>
      </div>
    </div>
  );
};

export default Offer;
