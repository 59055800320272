import React, { useState } from "react";
import cn from "classnames";
import styles from "./Faq.module.sass";
import Item from "./Item";
import Dropdown from "../../../components/Dropdown";

const items = [

  {
    title: "What is Mochi Prime?",
    answer: 'Mochi Prime is a monthly subscription service that supports the development of Mochi. While most features are free and don\'t need a subscription, supporting Mochi through subscriptions helps us keep Mochi alive and provide some extra benefits to those who subscribe.'
  },
  {
    title: "How many Discord servers is my subscription valid for?",
    answer: 'A subscription is valid for a single Discord server. If you wish to get premium for multiple servers, you need a subscription for each server.'
  },
  {
    title: "Can I transfer my premium subscription to another server?",
    answer: 'Yes. Once you are subscribed, you\'ll be able to transfer your subscription to another server of yours. However, it is not possible to transfer your subscription to a different Discord account.'

  },
  {
    title: "Is my payment secure?",
    answer: 'Yes. We never have access to your debit/credit card information. Payments are handled through Patreon, a payment processor trusted by millions of companies worldwide and protected with state-of-the-art technologies. We also never have access to your PayPal account info and your transactions are protected by PayPal\'s Purchase Protection for Buyers.'
  },

];

const Team = () => {
  const options = [];
  items.map((x) => options.push(x.title));

  const [category, setCategory] = useState(options[0]);

  return (
    <div className={cn("section-border-top", styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.top}>
          {/* <div className={cn("stage-small", styles.stage)}>
            learn how to get started
          </div> */}
          <h2 className={cn("h2", styles.title)}>Frequently Asked Questions</h2>
          {/* <div className={styles.info}>
            Join Fitness Pro community now to get free updates and also alot of
            freebies are waiting for you or <a href="/#">Contact Support</a>
          </div> */}

          {/* <div className={styles.nav}>
            {items.map((x, index) => (
              <button
                className={cn(styles.btn, {
                  [styles.active]: x.title === category,
                })}
                onClick={() => setCategory(x.title)}
                key={index}
              >
                {x.title}
              </button>
            ))}
          </div> */}
          {/* <Dropdown
            className={styles.dropdown}
            value={category}
            setValue={setCategory}
            options={options}
          /> */}
        </div>
        <div className={styles.list}>
          {items.map((x, index) => (
            <Item item={x} key={index} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Team;
