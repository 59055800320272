import React from "react";
import cn from "classnames";
import Slider from "react-slick";
import styles from "./Lifestyle.module.sass";
import Icon from "../../../components/Icon";
import ScrollParallax from "../../../components/ScrollParallax";
import { Link } from "react-router-dom";

const items = [


  {
    title: "Create your own Economy!",
    image: "/landing/images/content/Bank.png",
    image2x: "/landing/images/content/Bank.png",
    content:
      "Complete Mochi's work commands or create your own, custom work commands, that can earn your members' server currency. You can even add and create customized responses, cooldowns, and much more!",
  },

  {
    title: "Create and manage custom stores!",
    image: "/landing/images/content/Store.png",
    image2x: "/landing/images/content/Store.png",
    content:
      "Use Mochi's custom store creator to create custom stores on your server with ease! Simply create your custom store and add your inventory, and select how much stock is available. You can sell custom color roles, XP boosts, and consumables!",
  },






];

const SlickArrow = ({ currentSlide, slideCount, children, ...props }) => (
  <button {...props}>{children}</button>
);

const Economy = () => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    nextArrow: (
      <SlickArrow>
        <Icon name="arrow-next" size="14" />
      </SlickArrow>
    ),
    prevArrow: (
      <SlickArrow>
        <Icon name="arrow-prev" size="14" />
      </SlickArrow>
    ),
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 100000,
        settings: "unslick",
      },
    ],
  };

  return (
    <div className={cn("section-pb", styles.section)}>
      <div className={cn("container", styles.container)}>
        <Link to='/features'><button className={cn("button")}><Icon name="arrow-prev" size="14" style={{ marginLeft: 0, marginRight: '5px' }} /> Back</button></Link>

        <div className={styles.head}>
          {/* <div className={cn("stage-small", styles.stage)}>explore the app</div> */}
          <h2 className={cn("h2", styles.title)}>Economy</h2>
          <div className={styles.info}>
            Create your own economy and rule over your server!{" "}
          </div>
          {/* <Link
            className={cn("button-stroke", styles.button)}
            to="/class02-details"
          >
            <span>Join a class today</span>
            <Icon name="arrow-right" size="10" />
          </Link> */}
        </div>
        <div className={styles.wrap}>
          <Slider
            className={cn("lifestyle-slider", styles.slider)}
            {...settings}
          >
            {items.map((x, index) => (
              <ScrollParallax className={styles.item} key={index}>
                <div className={styles.row}>
                  <div className={styles.col}>
                    <div className={styles.details}>
                      <div className={styles.number}>0{index + 1}.</div>
                      <div className={styles.category}>{x.title}</div>
                      <div className={styles.content}>{x.content}</div>
                    </div>
                  </div>
                  <div className={styles.col}>
                    <img
                      srcSet={`${x.image2x}`}
                      src={x.image}
                      alt="Lifestyle"
                    />
                  </div>
                </div>
              </ScrollParallax>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Economy;
