import React from "react";
import cn from "classnames";
import Slider from "react-slick";
import styles from "./Lifestyle.module.sass";
import Icon from "../../../components/Icon";
import ScrollParallax from "../../../components/ScrollParallax";
import { Link } from "react-router-dom";

const items = [

  {
    title: "Anti-Caps",
    image: "/landing/images/anti-caps.svg",
    image2x: "/landing/images/anti-caps.svg",
    content:
      "Gently remind chatters to ease up on the caps lock and maintain a pleasant chat atmosphere.",
  },


  {
    title: "Anti-Mentions",
    image: "/landing/images/anti-mentions-2.svg",
    image2x: "/landing/images/anti-mentions-2.svg",
    content:
      "Prevent mass mentions by raiders and spammers, keeping your channels peaceful and orderly.",
  },

  {
    title: "Anti-Duplicates",
    image: "/landing/images/duplicates.svg",
    image2x: "/landing/images/duplicates.svg",
    content:
      "Stop repetitive word spamming and trolling, ensuring a positive server experience for everyone.",
  },


  {
    title: "Anti-Invites",
    image: "/landing/images/anti-invites-2.svg",
    image2x: "/landing/images/anti-invites-2.svg",
    content:
      `Keep your channels invite-free and focused on your community's interests.`,
  },


  {
    title: "Anti-Links",
    image: "/landing/images/anti-links.svg",
    image2x: "/landing/images/anti-links.svg",
    content:
      "Manage link posting with customizable whitelist and blacklist filters, allowing only approved content.",
  },


  {
    title: "Anti-Profanity",
    image: "/landing/images/anti-profanity.svg",
    image2x: "/landing/images/anti-profanity.svg",
    content:
      "Encourage respectful language by warning, kicking, or banning repeat profanity offenders.",
  },

  {
    title: "Anti-Emoji",
    image: "/landing/images/anti-emoji-2.svg",
    image2x: "/landing/images/anti-emoji-2.svg",
    content:
      "Prevent excessive emoji use and trolling, maintaining a fun yet balanced chat environment.",
  },

  {
    title: "Anti-Zalgo",
    image: "/landing/images/anti-zalgo.svg",
    image2x: "/landing/images/anti-zalgo.svg",
    content:
      "Stop disruptive Zalgo text by warning, kicking, or banning repeat offenders, keeping your channels neat and tidy.",
  },


];

const SlickArrow = ({ currentSlide, slideCount, children, ...props }) => (
  <button {...props}>{children}</button>
);

const Moderation = () => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    nextArrow: (
      <SlickArrow>
        <Icon name="arrow-next" size="14" />
      </SlickArrow>
    ),
    prevArrow: (
      <SlickArrow>
        <Icon name="arrow-prev" size="14" />
      </SlickArrow>
    ),
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 100000,
        settings: "unslick",
      },
    ],
  };

  return (
    <div className={cn("section-pb", styles.section)}>

      <div className={cn("container", styles.container)}>
        <Link to='/features'><button className={cn("button")}><Icon name="arrow-prev" size="14" style={{ marginLeft: 0, marginRight: '5px' }} /> Back</button></Link>

        <div className={styles.head}>


          {/* <div className={cn("stage-small", styles.stage)}>explore the app</div> */}
          <h2 className={cn("h2", styles.title)}>Moderation</h2>
          <div className={styles.info}>
            Discover our user-friendly Discord auto-moderation features, designed to keep your server safe and enjoyable 24/7.{" "}
          </div>
          {/* <Link
            className={cn("button-stroke", styles.button)}
            to="/class02-details"
          >
            <span>Join a class today</span>
            <Icon name="arrow-right" size="10" />
          </Link> */}
        </div>
        <div className={styles.wrap}>
          <Slider
            className={cn("lifestyle-slider", styles.slider)}
            {...settings}
          >
            {items.map((x, index) => (
              <ScrollParallax className={styles.item} key={index}>
                <div className={styles.row}>
                  <div className={styles.col}>
                    <div className={styles.details}>
                      <div className={styles.number}>{x.title}</div>
                      {/* <div className={styles.category}>{x.title}</div> */}
                      <div className={styles.content}>{x.content}</div>
                    </div>
                  </div>
                  <div className={styles.col}>
                    <img
                      srcSet={`${x.image2x} 2x`}
                      src={x.image}
                      style={{ width: '170px', borderRadius: 0 }}
                      alt="Lifestyle"
                    />
                  </div>
                </div>
              </ScrollParallax>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Moderation;
