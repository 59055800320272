import React from "react";
import cn from "classnames";
import Slider from "react-slick";
import styles from "./Lifestyle.module.sass";
import Icon from "../../../components/Icon";
import ScrollParallax from "../../../components/ScrollParallax";
import { Link } from "react-router-dom";
const items = [
  {
    title: "Magical Embeds in a Flash!",
    image: "/landing/images/content/Paint.png",
    image2x: "/landing/images/content/Paint.png",
    content:
      "With our Moon-tastic Embed Builder, you can create enchanting, colorful, and professional embeds for your messages and server! Add a touch of magic with our easy-to-use emoji picker, and choose your favorite emotes right from your dashboard! It's like casting a spell on your server! ",
  },
  {
    title: "Choose Your Destiny with Reaction Roles! ",
    image: "/landing/images/content/Roles.png",
    image2x: "/landing/images/content/Roles.png",
    content:
      "Create roles that your members can react to with our Moon-tastic Reaction Role Dashboard! Use our handy emoji picker to select and assign custom emojis, letting your members choose their own magical destinies! ",
  },
  {
    title: "Mochi Messages to the Rescue!",
    image: "/landing/images/content/Chat.png",
    image2x: "/landing/images/content/Chat.png",
    content:
      "Let our Moon-tastic Virtual Assistant send out automated messages for you! Need to send weekly or monthly reminders? No problem! Our Sailor Messages will make sure your reminders are delivered right on time, like a true hero! ",
  },

  {
    title: "Moon Power!",
    image: "/landing/images/content/Tablet.png",
    image2x: "/landing/images/content/Tablet.png",
    content:
      "Over time, your channels might get cluttered with old messages and events. Fear not! Our Moon Purge Power will detect and remove unwanted messages, keeping your server clean and sparkly like the Moon Palace!",
  },

  {
    title: "Share Your Adventures Across the Universe! ",
    image: "/landing/images/content/Socials.png",
    image2x: "/landing/images/content/Socials.png",
    content:
      `As a content creator, let your members know when you go live on Twitch, or share your latest Instagram, Twitter, and YouTube updates! Our Moon-tastic Social Posts will keep your community engaged and excited, just like when I save the day with the Sailor Scouts!`,
  },




];

const SlickArrow = ({ currentSlide, slideCount, children, ...props }) => (
  <button {...props}>{children}</button>
);

const Utilities = () => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    nextArrow: (
      <SlickArrow>
        <Icon name="arrow-next" size="14" />
      </SlickArrow>
    ),
    prevArrow: (
      <SlickArrow>
        <Icon name="arrow-prev" size="14" />
      </SlickArrow>
    ),
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 100000,
        settings: "unslick",
      },
    ],
  };

  return (
    <div className={cn("section-pb", styles.section)}>
      <div className={cn("container", styles.container)}>
        <Link to='/features'><button className={cn("button")}><Icon name="arrow-prev" size="14" style={{ marginLeft: 0, marginRight: '5px' }} /> Back</button></Link>

        <div className={styles.head}>
          {/* <div className={cn("stage-small", styles.stage)}>explore the app</div> */}
          <h2 className={cn("h2", styles.title)}>Utilities</h2>
          <div className={styles.info}>
            Powerful tools to help manage your server and time efficiently.{" "}
          </div>
          {/* <Link
            className={cn("button-stroke", styles.button)}
            to="/class02-details"
          >
            <span>Join a class today</span>
            <Icon name="arrow-right" size="10" />
          </Link> */}
        </div>
        <div className={styles.wrap}>
          <Slider
            className={cn("lifestyle-slider", styles.slider)}
            {...settings}
          >
            {items.map((x, index) => (
              <ScrollParallax className={styles.item} key={index}>
                <div className={styles.row}>
                  <div className={styles.col}>
                    <div className={styles.details}>
                      <div className={styles.number}>0{index + 1}.</div>
                      <div className={styles.category}>{x.title}</div>
                      <div className={styles.content}>{x.content}</div>
                    </div>
                  </div>
                  <div className={styles.col}>
                    <img
                      srcSet={`${x.image2x} 2x`}
                      src={x.image}
                      alt="Lifestyle"
                    />
                  </div>
                </div>
              </ScrollParallax>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Utilities;
