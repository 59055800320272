import React from "react";
import cn from "classnames";
import styles from "./ValueProps.module.sass";
import ScrollParallax from "../ScrollParallax";

const items = [
  {
    title: "Moderation",
    content:
      "Powerful auto-moderation settings help you manage your server from pesky spammers to keeping your server PG and free from suspicious links.",
    icon:
      `<img
    srcSet="/landing/images/mod-mochi.svg 2x"
    src="/landing/images/mod-mochi.svg"
    class='feature'
    alt="level"
  />`  },
  {
    title: "Utilities",
    content:
      "Mochi comes with a complete range of tools to help you manage your day-to-day server activities, from posting automated messages to creating reaction roles.",
    icon:
      `<img
    srcSet="/landing/images/utility-mochi.svg 2x"
    src="/landing/images/utility-mochi.svg"
    class='feature'
    alt="level"
  />`  },
  {
    title: "Leveling",
    content:
      "Rule over your kingdom with Mochi's leveling system that unlocks levels, roles, and ranks by collecting XP for voice and chat activity! You decide how easy or hard it is for your members to level up!",
    icon:
      `<img
      srcSet="/landing/images/level-mochi.svg 2x"
      src="/landing/images/level-mochi.svg"
      class='feature'
      alt="level"
    />`,
  },


];


const items2 = [
  {
    title: "Economy",
    content:
      "Members earn coins by completing Mochi work commands, or your own with customized responses and cooldowns, then shop with their hard-earned coins at your custom stores for custom items and rewards!",
    icon:
      `<img
    srcSet="/landing/images/money-mochi.png"
    src="/landing/images/money-mochi.png"
    class='feature'
    alt="level"
  />`  },

  {
    title: "Partnerships",
    content:
      "With Mochi, you can build connections with other communities through partnerships or manage your paid promotions with ease through affiliate ads. Keep track of your progress with partnerships and affiliate counts, ranks, and leaderboards. ",
    icon:
      `<img
    srcSet="/landing/images/partner-mochi.svg"
    src="/landing/images/partner-mochi.svg"
    class='feature'
    alt="level"
  />`  },
  {
    title: "Fun!",
    content:
      "Keep your members entertained with fun activities like trivia, questions of the day, giveaways and movie nights, polls, and more!",
    icon:
      `<img
    srcSet="/landing/images/fun-mochi.svg"
    src="/landing/images/fun-mochi.svg"
    class='feature'
    alt="level"
  />`  },



];



const ValueProps = ({ className }) => {
  return (
    <div className={cn(className, styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.head}>
          <div className={cn("stage-small", styles.stage)}>
            Everything you need to create beautiful servers
          </div>
          <div className={cn("h2", styles.title)}>
            Smart tools to create an amazing experience
          </div>
        </div>
        <div className={styles.list}>
          {items.map((x, index) => (
            <ScrollParallax className={styles.item} key={index}>
              <div
                className={styles.icon}
                dangerouslySetInnerHTML={{ __html: x.icon }}
              ></div>
              <div className={styles.category}>{x.title}</div>
              <div className={styles.content}>{x.content}</div>
            </ScrollParallax>
          ))}
        </div>

        <div className={styles.list}>
          {items2.map((x, index) => (
            <ScrollParallax className={styles.item} key={index}>
              <div
                className={styles.icon}
                dangerouslySetInnerHTML={{ __html: x.icon }}
              ></div>
              <div className={styles.category}>{x.title}</div>
              <div className={styles.content}>{x.content}</div>
            </ScrollParallax>
          ))}
        </div>



      </div>
    </div>
  );
};

export default ValueProps;
