import React from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import styles from "./Program.module.sass";
import Icon from "../../../components/Icon";
import ScrollParallax from "../../../components/ScrollParallax";

const items = [
  {
    title: "Moderation",
    url: "/moderation",
    color: "#23262F",
    images: "/landing/images/mod-mochi.svg",
    alt: "moderation",
  },
  {
    title: "Leveling",
    url: "/leveling",
    color: "#9757D7",
    images: "/landing/images/level-mochi.svg",
    alt: "lightning",
  },
  {
    title: "Utilities",
    url: "/utilities",
    color: "#3772FF",
    images: "/landing/images/utility-mochi.svg",
    alt: "utilities",
  },

  {
    title: "Economy",
    url: "/economy",
    color: "#3772FF",
    images: "/landing/images/money-mochi.png",
    alt: "lightning",
  },

  {
    title: "Partnerships",
    url: "/partnerships",
    color: "#3772FF",
    images: "/landing/images/partner-mochi.svg",
    alt: "lightning",
  },


  {
    title: "Fun!",
    url: "/fun",
    color: "#3772FF",
    images: "/landing/images/fun-mochi.svg",
    alt: "lightning",
  },

];

const Program = () => {
  return (
    <div style={{paddingTop:'30px',paddingBottom:'120px'}}>
      <div className={cn("container", styles.container)}>
        <div className={styles.wrap}>
          <div className={styles.col}>

            <div className={cn("features-title", styles.stage)}>
              Features
            </div>


            <div style={{ textTransform: 'none', fontSize: '16px' }} className={cn("stage-small", styles.stage)}>
              Explore Mochi's features!
            </div>
            <div className={styles.list}>
              {items.map((x, index) => (
                <ScrollParallax className={styles.item} key={index}>
                  <Link className={styles.link} to={x.url}>
                    <div
                      className={styles.icon}
                    >
                      <img src={x.images} alt={x.alt} />
                    </div>
                    <div className={styles.subtitle}>{x.title}</div>
                    <div className={styles.arrow}>
                      <Icon name="arrow-right-empty" size="14" />
                    </div>
                  </Link>
                </ScrollParallax>
              ))}
            </div>
          </div>
          <div className={styles.col}>
            <div className={styles.preview}>
              <img
                srcSet="/landing/images/content/main-mochi.svg"
                src="/landing/images/content/main-mochi.svg"
                alt="Mochi"
              />
            </div>
            <div>
              Unlock Mochi's full potential with Mochi Prime!
            </div>
            <br>
            </br>
            <a href="https://www.patreon.com/mochiprime">
              <button className={cn("button", styles.button)} to="">
                Upgrade Now!
              </button>
            </a>

          </div>
        </div>
      </div>
    </div>
  );
};

export default Program;
