import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./Intro.module.sass";
import ScrollParallax from "../ScrollParallax";

const Intro = ({ scrollToRef }) => {
  return (
    <div className={styles.section} ref={scrollToRef}>
      <div className={cn("container", styles.container)}>
        <div className={styles.gallery}>
          <ScrollParallax className={styles.preview} animateIn="fadeInUp">
            <img
              srcSet="/landing/images/all-mochis.png"
              src="/landing/images/all-mochis.png"
              alt="Fun"
            />
          </ScrollParallax>

        </div>
        <div className={styles.wrap}>
          <h2 className={cn("h1", styles.title)}>
            What is Mochi?
          </h2>
          <div className={styles.text}>
            Meet Mochi, the cutest multi-purpose Discord bot! Mochi's here to make your server fun with moderation tools, giveaways, and more. Plus, Mochi is a BFF for Twitch streamers and content creators with alerts and updates. Mochi brings the fun with Movie Night, trivia, and games!
          </div>
          <a className={cn("button", styles.button)} href="/auth/discord">
            Try Mochi Now!
          </a>
        </div>
      </div>
    </div>
  );
};

export default Intro;
